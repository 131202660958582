<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('inventoryStoreCountingReqAdd')"
          @click="$router.push('inventoryStoreCountingReq/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('inventoryStoreCountingReqDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :filters="filters"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه طلب صرف المخزني
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column
        field="code"
        header="الكود"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث بالكود"
          />
        </template>
      </Column>
      <Column
        field="date"
        header="التاريخ"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.date) }}
        </template>
        <template #filter>
          <InputText
            type="date"
            v-model="filters['date']"
            class="p-column-filter"
            placeholder="بحث بالتاريخ"
          />
        </template>
      </Column>

      <Column
        field="clientsId.name"
        filterMatchMode="contains"
        header="العميل"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['clientsId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم العميل"
          />
        </template>
      </Column>

      <Column
        field="usersId.name"
        filterMatchMode="contains"
        v-if="$checkRoles('inventoryStoreCountingAll')"
        header="الموظف"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['usersId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم الموظف"
          />
        </template>
      </Column>
      <Column
        field="warehouseId.name"
        header="المستودع"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['warehouseId.name']"
            class="p-column-filter"
            placeholder="بحث بالمستودع"
          />
        </template>
      </Column>

      <Column field="createdAt" header="وقت الانشاء" :sortable="true">
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.createdAt) }}
        </template>
      </Column>
      <Column field="dxDate" header="تم الاعتماد" :sortable="true">
        <template #body="slotProps">
          <InputSwitch
            v-if="$checkRoles('inventoryStoreCountingDX')"
            v-model="slotProps.data.dx"
            :disabled="slotProps.data.dx"
            @change="dx(slotProps.data)"
          />
          <br />
          {{
            slotProps.data.dxDate
              ? $durationFormatFull(slotProps.data.dxDate)
              : '--'
          }}
        </template>
      </Column>

      <Column field="dxsDate" header="تم الصرف" :sortable="true">
        <template #body="slotProps">
          <InputSwitch
            v-if="$checkRoles('inventoryStoreCountingDXS') && slotProps.data.dx"
            v-model="slotProps.data.dxs"
            :disabled="slotProps.data.dxs"
            @change="dxs(slotProps.data)"
          />
          <br />
          {{
            slotProps.data.dxsDate
              ? $durationFormatFull(slotProps.data.dxsDate)
              : '--'
          }}
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <!--  <a
            :href="windowOrigin + '/admin/print/' + slotProps.data.id"
            target="_blank"
          >
            <Button
              icon="pi pi-download"
              class="p-button-help p-ml-2  p-button-rounded"
            />
          </a> -->

          <Button
            type="button"
            @click="
              $router.push(
                'inventoryStoreCountingReq/edit/' + slotProps.data.id,
              )
            "
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            v-if="$checkRoles('inventoryStoreCountingReqEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button>

          <Button
            type="button"
            icon="pi pi-trash"
            v-tooltip="'حذف'"
            v-if="$checkRoles('inventoryStoreCountingReqDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-ml-2  p-button-rounded"
          ></Button>

          <!-- <Button
            type="button"
            @click="dx(slotProps.data)"
            icon="pi pi-plus" v-tooltip="'اضافه جديد'"
            v-if="!slotProps.data.dx && $checkRoles('inventoryStoreCountingDX')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button> -->
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <p v-html="slotProps.data.texxxt"></p>
          <!-- <DataTable
            class="p-datatable-customers table table-striped"
            v-if="slotProps.data.texxxt"
            :value="[slotProps.data]"
          >
            <Column  header="البيان">
              <Template #body="slotProps2">
                
              </Template>
            </Column>
          </DataTable> -->
          <DataTable
            class="p-datatable-customers table table-striped"
            v-if="slotProps.data.note"
            :value="[slotProps.data]"
          >
            <Column field="note" header="ملاحظات" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],

      windowOrigin: window.origin,
      filters2: {},
      expandedRows: [],
      filters: {},
      loading: true,
    };
  },
  methods: {
    getData() {
      const user = JSON.parse(localStorage.admin);

      const url = this.$checkRoles('inventoryStoreCountingAll')
        ? ''
        : '?usersId=' + user.id;
      this.$http.get(`inventoryStoreCountingReq${url}`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`inventoryStoreCountingReq/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`inventoryStoreCountingReq/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
    dx(item) {
      this.$http.put(`inventoryStoreCountingReq/dx/${item.id}`, item).then(
        () => {
          this.getData();
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم الترحيل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    dxs(item) {
      this.$http.put(`inventoryStoreCountingReq/dxs/${item.id}`, item).then(
        () => {
          this.getData();
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم الصرف بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    if (!this.$checkRoles('inventoryStoreCountingReqVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
